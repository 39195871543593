import React from 'react';
import { ListItemButton, ListItemText, Stack } from '@mui/material';
import CountBadge from 'common/components/CountBadge';

export default function SubMenuItem({ title, clickHandler, sideBarOpen, itemCount }) {
  return (
    <ListItemButton
      sx={{ padding: 0, marginLeft: '3rem' }}
      onClick={clickHandler}
    >
      <Stack direction="row" spacing={1}>
        <ListItemText
          data-testid="SubMenuItems"
          primary={title}
          sx={{ opacity: sideBarOpen ? 1 : 0 }}
          primaryTypographyProps={{ fontSize: '0.75rem' }}
        />
        {(itemCount >= 0) && <CountBadge count={itemCount} />}
      </Stack>
    </ListItemButton>
  );
}
