import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MAIN_HOST } from 'common/AppConstant';
import { TABULAR_VIEWS } from 'common/Constants';

export const search = createAsyncThunk('evluation_search', async (data) => {
  const response = await axios.post(`${MAIN_HOST}/evaluation/search`, data);

  return response.data;
});

export const getById = createAsyncThunk('evluation_getById', async (id) => {
  const response = await axios.get(`${MAIN_HOST}/evaluation/id/${id}`);

  return response.data;
});

export const save = createAsyncThunk('evluation_save', async (data) => {
  const response = await axios.post(`${MAIN_HOST}/evaluation`, data);
  const updatedResult = await axios.get(`${MAIN_HOST}/evaluation/id/${response.data.id}`);
  return updatedResult.data;
});

export const updateEvaluation = createAsyncThunk('evluation_update', async (data) => {
  const response = await axios.post(`${MAIN_HOST}/evaluation/update`, data);
  const updatedResult = await axios.get(`${MAIN_HOST}/evaluation/id/${response.data.id}`);
  return updatedResult.data;
});

export const getEvaluationResult = createAsyncThunk('evluation_getEvaluationResult', async (id) => {
  const response = await axios.get(`${MAIN_HOST}/evaluation/result/id/${id}`);
  return response.data;
});

export const getStatusById = createAsyncThunk('evluation__getStatusById', async (id) => {
  const response = await axios.get(`${MAIN_HOST}/evaluation/status/id/${id}`);
  return response.data;
});

export const getNpiLinkageByEId = createAsyncThunk('evluation_getNpiLinkageByEId', async (id) => {
  const response = await axios.get(`${MAIN_HOST}/evaluation/npi/linkage/detail/id/${id}`);
  return response.data;
});

export const getMsaSuggestions = createAsyncThunk('lookup/msa', async () => {
  const response = await axios.get(`${MAIN_HOST}/lookup/msa`);
  return response.data;
});

export const updateTheDetailInclusion = createAsyncThunk('evluation_updateTheDetailInclusion', async (data) => {
  const response = await axios.put(`${MAIN_HOST}/evaluation/detail/${data.id}/exclusion/toggle/${data.included}`);
  return response.data;
});

export const bulkUpdateTheDetailInclusion = createAsyncThunk('evluation_bulkUpdateTheDetailInclusion', async (data) => {
  const response = await axios.post(`${MAIN_HOST}/evaluation/detail/exclusion/`, data);
  return response.data;
});

export const getEvaluationResultDetailRatio = createAsyncThunk('evluation_getEvaluationResultDetailRatio', async (data) => {
  const response = await axios.post(`${MAIN_HOST}/evaluation/result/all-ratio/`, data);
  return response.data;
});

export const getBillingClassRatio = createAsyncThunk('getBillingClassRatio', async (data) => {
  const response = await axios.post(`${MAIN_HOST}/evaluation/result/billing-class-ratio/`, data);
  return response.data;
});

export const saveWeightsFile = createAsyncThunk('evaluation_saveWeightsFile', async (data) => {
  const formData = new FormData();
  formData.append('weightsFile', data.file);
  formData.append('weightsName', data.name);
  const response = await axios.post(`${MAIN_HOST}/evaluation/weights-file`, formData);
  return response.data;
});

export const getAllCustomizedWeights = createAsyncThunk('evaluation/customized-weights/all', async () => {
  const response = await axios.get(`${MAIN_HOST}/evaluation/customized-weights/all`);
  return response.data;
});

export const deleteEvaluationById = createAsyncThunk('evluation_deleteEvaluationById', async (id) => {
  const response = await axios.delete(`${MAIN_HOST}/evaluation/id/${id}`);
  return response.data;
});

export const getAllRollups = createAsyncThunk('evaluation/getAllRollups', async (id) => {
  const response = await axios.get(`${MAIN_HOST}/evaluation/rollup/${id}`);
  return response.data;
});

export const getEvaluationResultDetails = createAsyncThunk('evaluation/getResultsDetails', async (data) => {
  const response = await axios.post(`${MAIN_HOST}/evaluation/result/detail/`, data);
  return response.data;
});

export const togglePrivateStudy = createAsyncThunk('evaluation/private-evaluation/toggle', async (id) => {
  const response = await axios.get(`${MAIN_HOST}/evaluation/private-evaluation/toggle/${id}`);
  return response.data;
});

export const getNPIRates = createAsyncThunk('getNPIRates', async (data) => {
  const response = await axios.post(`${MAIN_HOST}/evaluation/study-result/npi-info`, data);
  return response.data;
});

export const getNPIRatesCount = createAsyncThunk('getNPIRatesCount', async (data) => {
  const response = await axios.post(`${MAIN_HOST}/evaluation/study-result/npi-total-count`, data);
  return response.data;
});

export const saveCensusFile = createAsyncThunk('census/save', async (data) => {
  const formData = new FormData();
  formData.append('censusFile', data.file);
  formData.append('censusName', data.name);
  const response = await axios.post(`${MAIN_HOST}/census/save`, formData);
  return response.data;
});

export const getStudiesList = createAsyncThunk('getStudiesList', async (data) => {
  const response = await axios.post(`${MAIN_HOST}${TABULAR_VIEWS()[data.viewName].studiesListEndPoint}${data.id}`);
  return response.data;
});

export const getNetworkRating = async (msaNo) => {
  const response = await axios.get(`${MAIN_HOST}/evaluation/market-study/rated-network-list/${msaNo}`);
  return response.data;
};

export const getStateNetworkRating = async (state) => {
  const response = await axios.get(`${MAIN_HOST}/home/rated-network-list/${state}`);
  return response.data;
};

export const exportCSVFileAsZIP = createAsyncThunk('/export/study', async (data) => {
  const formData = new FormData();
  formData.append('zipFileName', data.zipFileName);
  formData.append('studyType', data.studyType);
  formData.append('evaluationId', data.evaluationId);
  const response = await axios.post(`${MAIN_HOST}/export/study`, formData, { responseType: 'blob' });
  return response.data;
});
export default search;
