export const COOKIE_JWT = 'JWT';

export const MAIN_HOST = process.env.REACT_APP_BACKEND_HOST;

export const FORMAT_DATE = 'MM/DD/YYYY';

export const DATA_NOT_AVAILABLE = '-';

export const MULTIPLAN_OKTA_SSO = process.env.REACT_APP_MULTIPLAN_OKTA_SSO;

export const SPACE_AFTER_COMMA = /(?<=,)(\s*)/g;

export const NPI_REGEX = /\b\d{10}\b/g;

export const CLIENT_PORTAL = process.env.REACT_APP_CLIENT_PORTAL;

export const SIGN_OUT = false;

export const CLIENT_PORTAL_CUSTOMER_SERVICE = `${process.env.REACT_APP_CLIENT_PORTAL}/webcenter/portal/client/pages_customerservice/opennewservicecase1`;

export const SHOW_POPULATION_STUDY = process.env.REACT_APP_SHOW_POP_STUDY === 'true';

export const USE_APPD = process.env.REACT_APP_USE_APPD === 'true';

export const APP_DYNAMICS_APP_KEY = process.env.REACT_APP_DYNAMICS_APP_KEY;

export const USE_CLARITY = process.env.REACT_APP_USE_CLARITY === 'true';

export const ZIP_FILE_EXTENSION = '.zip';

export const SHOW_PANORAMA_VERSION = process.env.REACT_APP_SHOW_PANORAMA_VERSION === 'true';
