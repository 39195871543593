import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
  Button,
  TextField,
  Link,
  Grid,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { FORMAT_RATE } from 'common/util/commonUtil';
import CustomDivider from 'common/components/CustomDivider';
import InfiniteScroll from 'common/components/InfiniteScroll';
import { NO_MORE_RECORDS_TO_LOAD, NO_RECORDS_TO_DISPLAY } from 'common/Constants';

function NPIRateDrawer({ open, onClose, npiRates, NPIRateCount, billingDetails,
  fetchNpiDetails, hasMore, page, npiRecordsLoad, setPage }) {
  const theme = useTheme();
  const [NPIRateRecords, setNPIRateRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [isInputChanged, setIsInputChanged] = useState(false);
  const [isDataFiltered, setIsDataFiltered] = useState(false);
  const scrollBarRef = useRef(null);
  let incrementID = 0;

  useEffect(() => {
    setNPIRateRecords(NPIRateCount);
  }, [NPIRateCount]);

  const fetchMoreData = async () => {
    await fetchNpiDetails({ ...billingDetails, page, searchTerm: isDataFiltered ? searchTerm : '' });
  };

  const scrollToTop = () => {
    if (scrollBarRef.current) {
      scrollBarRef.current.scrollTop = 0;
    }
  };

  const handleFilter = (searchTermValue) => {
    setSearchTerm(searchTermValue);
    setPage(0);
    const setInitialData = searchTermValue === '';
    setIsInputChanged(!setInitialData);
    setIsDataFiltered(!setInitialData);
    fetchNpiDetails({ ...billingDetails, page: 0, searchTerm: searchTermValue }, setInitialData);
    scrollToTop();
  };

  const handleClose = () => {
    setSearchTerm('');
    setIsInputChanged(false);
    onClose();
  };

  const filterButton = () => (
    <Box sx={{ paddingTop: '10px' }}>
      <TextField
        id="input-taxId"
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setIsInputChanged(true);
        }}
        fullWidth
        label="Filter"
        labelId="label-tierorder"
      />
      <div style={{ float: 'right', paddingTop: '10px' }}>
        <Link
          component="button"
          variant="body2"
          sx={{
            paddingRight: '10px',
            pointerEvents: !isInputChanged ? 'none' : 'auto',
            color: !isInputChanged ? 'grey' : 'blue',
            cursor: !isInputChanged ? 'not-allowed' : 'pointer',
          }}
          onClick={() => handleFilter('')}
          disabled={!isInputChanged}>
          Clear
        </Link>
        <Button
          variant="contained"
          onClick={() => handleFilter(searchTerm)}
          disabled={searchTerm.length === 0}
          sx={{ textTransform: 'capitalize' }}
        >
          Apply
        </Button>
      </div>
    </Box>
  );

  const getHeightCSS = () => {
    const withFilter = {
      header: '34vh',
      list: '63vh',
    };
    const withoutFilter = {
      header: billingDetails.networkLabel && billingDetails.networkLabel.length > 24 ? '22vh' : '20vh',
      list: billingDetails.networkLabel && billingDetails.networkLabel.length > 24 ? '77vh' : '79vh',
    };
    return NPIRateRecords >= 20 ? withFilter : withoutFilter;
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      id="NPI_DRAWER"
      onClose={handleClose}>
      <Grid container sx={{ height: '100vh', width: '330px' }}>
        <Grid item xs={12} sx={{ paddingTop: '64px', height: getHeightCSS().header }}>
          <List sx={{ padding: '0px !important' }}>
            <ListItem>
              <ListItemText
                primary={(
                  <Grid container>
                    <Grid item xs={11}>
                      <Typography id="NPI_BILLING_NAME" variant="h2" color={theme.palette.darkest.main}>{billingDetails.networkLabel}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={handleClose} sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '0px' }}>
                        <CancelIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                secondary={(
                  <>
                    <Typography id="NPI_BILLING_CODE" variant="body1" color={theme.palette.darkest.main}>
                      {`${billingDetails.billingCodeType} ${billingDetails.billingCode}`}
                    </Typography>
                    <Typography id="NPI_RECORDS_COUNT" variant="body1" color={theme.palette.darkest.main}>
                      {`NPI/Rate Records: ${NPIRateRecords.toLocaleString('en-US')}`}
                    </Typography>
                    {NPIRateRecords >= 20 && filterButton()}
                  </>
                )} />
            </ListItem>
          </List>
          <CustomDivider />
        </Grid>
        <Grid
          item
          xs={12}
          ref={scrollBarRef}
          sx={{ overflow: 'auto', height: getHeightCSS().list }}>
          <InfiniteScroll
            fetchMore={fetchMoreData}
            hasMore={hasMore}
            loader={<div>Loading...</div>}
            message={npiRates.length > 0 ? NO_MORE_RECORDS_TO_LOAD
              : (npiRecordsLoad && NO_RECORDS_TO_DISPLAY)}
          >
            {npiRates.map((item) => {
              incrementID += 1;
              return (
                <React.Fragment key={`${incrementID}-${item.npi}-${item.rate}`}>
                  <List sx={{ paddingTop: '0px', paddingBottom: '0px' }} id={`${incrementID}-${item.npi}-${item.rate}`}>
                    <ListItem id={`NPIRateDrawerList-${item.id}`} sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                      <ListItemText
                        primary={item.providerName}
                        secondary={(
                          <>
                            <Typography component="span" variant="body1" color="text.primary">
                              {item.npi}
                            </Typography>
                            <br />
                            <Typography component="span" variant="body1" color="text.primary">
                              {FORMAT_RATE(item.rate)}
                            </Typography>
                          </>
                        )}
                      />
                    </ListItem>
                  </List>
                  <CustomDivider />
                </React.Fragment>
              );
            })}
          </InfiniteScroll>
        </Grid>
      </Grid>
    </Drawer>
  );
}

export default NPIRateDrawer;
