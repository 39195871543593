import React, { useState, useEffect, useRef } from 'react';

function InfiniteScroll({ children, fetchMore, hasMore, loader, message }) {
  const [isFetching, setIsFetching] = useState(false);
  const observer = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isFetching && hasMore) {
          setIsFetching(true);
          fetchMore().then(() => {
            setIsFetching(false);
          });
        }
      });
    }, [hasMore]);

    if (observer.current) {
      observer.current.observe(document.getElementById('sentinel'));
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [fetchMore, isFetching]);

  return (
    <div data-testid="infinite-scroll">
      {children}
      <div id="sentinel" style={{ height: '20px' }} />
      {!isFetching && hasMore && loader}
      {!hasMore && <div style={{ marginLeft: '16px', fontWeight: 'bold' }}>{message}</div>}
    </div>
  );
}

export default InfiniteScroll;
