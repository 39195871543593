/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import store from 'app/store';
import { get } from 'lodash';
import { FORMAT_RATE } from 'common/util/commonUtil';
import { getNPIRates, getNPIRatesCount } from 'features/evaluation/state/service';
import { Typography, TableRow, Table, TableHead, TableBody, Link } from '@mui/material';
import HorizontalRatingBar from 'common/components/HorizontalRatingBar';
import { EVALTYPE, POPULATION_NETWORK_RESULT } from 'common/Constants';
import NPIRateDrawer from './NPIRateDrawer';
import { PopStudyTableCell } from './PopulationStyles';

function NpiCountColumn({ networkResult }) {
  return (
    <PopStudyTableCell align="right" sx={{ paddingRight: '5%' }}>
      <Typography>{networkResult?.npiCount?.toLocaleString('en-US')}</Typography>
    </PopStudyTableCell>
  );
}
function NpiCountColumnLink({ networkResult, billingDetailsForNetwork,
  networkLabel, setBillingDetailsForNetwork }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [initialNPIDetails, setInitialNPIDetails] = useState([]);
  const [NPIDetails, setNPIDetails] = useState([]);
  const [NPIRateCount, setNPIRateCount] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(0);
  // added this to show no more records to load in drawer when no records
  const [npiRecordsLoad, setNpiRecordsLoad] = useState(false);

  const npiCountFocusStyle = {
    ...(drawerOpen
      && {
        outline: '1px solid black',
        outlineOffset: '0.1rm',
      }),
  };

  const fetchNpiDetails = (request, setInitialData = false) => {
    if (setInitialData && initialNPIDetails.length > 0) {
      setNPIDetails(initialNPIDetails);
      setNpiRecordsLoad(true);
      setPage(1);
      setHasMore(NPIRateCount >= 20);
    } else {
      store.dispatch(getNPIRates(request))
        .unwrap()
        .then((response) => {
          let updatedNpiDetails = [...response.content];
          if (request.page && request.page > 0) {
            updatedNpiDetails = [...NPIDetails, ...response.content];
          }
          if (request.page === 0 && request.searchTerm === '') {
            setInitialNPIDetails([...response.content]);
          }
          setNPIDetails(updatedNpiDetails);
          setNpiRecordsLoad(true);
          const noMoreData = updatedNpiDetails.length === response.totalElements;
          if (!noMoreData) {
            setHasMore(true);
            setPage((prevPage) => prevPage + 1);
          } else {
            setHasMore(false);
            setPage(0);
          }
        });
    }
  };

  const handleDrawerOpen = (request) => {
    setDrawerOpen(true);
    store.dispatch(getNPIRatesCount(request))
      .unwrap()
      .then((response) => {
        setNPIRateCount(response);
        setNpiRecordsLoad(true);
      });

    fetchNpiDetails(request);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setNPIDetails([]);
    setNPIRateCount(0);
    setPage(0);
    setNpiRecordsLoad(false);
  };

  return (
    <PopStudyTableCell align="right">
      <Link
        id="button-npiCount"
        component="button"
        color="inherit"
        onClick={() => handleDrawerOpen(billingDetailsForNetwork)}
        sx={npiCountFocusStyle}
      >
        <Typography>{networkResult?.npiCount?.toLocaleString('en-US')}</Typography>
      </Link>
      {NPIDetails
        && (
          <NPIRateDrawer
            open={drawerOpen}
            onClose={handleDrawerClose}
            npiRates={NPIDetails || []}
            NPIRateCount={NPIRateCount}
            billingDetails={{ ...billingDetailsForNetwork, networkLabel }}
            setBillingDetails={setBillingDetailsForNetwork}
            fetchNpiDetails={fetchNpiDetails}
            hasMore={hasMore}
            page={page}
            npiRecordsLoad={npiRecordsLoad}
            setPage={setPage} />
        )}
    </PopStudyTableCell>
  );
}

function displayRating(networkResult, showProcessBar = false) {
  let rating = '';
  if (networkResult?.usabilityRating != null) {
    if (networkResult?.usabilityRating === 0.0) {
      rating = 0;
    } else if (networkResult?.usabilityRating < 0.50) {
      rating = '< 0.5';
    } else if (networkResult?.usabilityRating > 0.49) {
      rating = showProcessBar ? (
        <HorizontalRatingBar
          rating={networkResult.usabilityRating}
          source={POPULATION_NETWORK_RESULT} />
      ) : networkResult?.usabilityRating.toFixed(1);
    }
  }
  return rating;
}

function DataUsibilityColumn({ networkResult }) {
  return (
    <PopStudyTableCell align="right" padding="none">
      <Typography marginLeft=".2rem" paddingRight="5px">
        {displayRating(networkResult)}
      </Typography>
    </PopStudyTableCell>
  );
}

function PopulationDataUsibilityColumn({ networkResult }) {
  return (
    <PopStudyTableCell align="left" padding="none">
      <Typography marginLeft=".2rem">
        {displayRating(networkResult, true)}
      </Typography>
    </PopStudyTableCell>
  );
}
function MrfRateColumn({ networkResult }) {
  return (
    <PopStudyTableCell align="right" padding="none">
      <Typography>{networkResult?.mrfRate && FORMAT_RATE(networkResult.mrfRate)}</Typography>
    </PopStudyTableCell>
  );
}
function MedicarePercentColumn({ networkResult }) {
  return (
    <PopStudyTableCell align="right">
      <Typography>{networkResult?.percentMedicare ? `${networkResult?.percentMedicare}%` : null}</Typography>
    </PopStudyTableCell>
  );
}

function PopultionTableHeaders() {
  return (
    <>
      <PopStudyTableCell width="35%" />
      <PopStudyTableCell width="30%" />
      <PopStudyTableCell width="35%" />
    </>
  );
}

function StudyTableHeaders() {
  return (
    <>
      <PopStudyTableCell width="20%" padding="none" />
      <PopStudyTableCell width="40%" />
      <PopStudyTableCell width="20%" padding="none" />
      <PopStudyTableCell width="20%" />
    </>
  );
}
export default function NetworkResult({ networkResult,
  evalType,
  categoryDetails,
  networkCode,
  networkLabel,
  billingClass }) {
  const evaluation = useSelector((state) => state.evaluation.evaluation);
  const evalId = get(evaluation, 'id');
  const [billingDetailsForNetwork, setBillingDetailsForNetwork] = useState({
    evaluationId: evalId,
    networkCode,
    billingClass,
    billingCodeType: categoryDetails?.billingCodeType,
    billingCode: categoryDetails?.billingCode,
    direction: 'DESC',
    page: 0,
    searchTerm: '',
    size: 20,
    sortProperty: '',
  });

  const studyColumns = (
    <>
      <MedicarePercentColumn networkResult={networkResult} />
      <MrfRateColumn networkResult={networkResult} />
      <NpiCountColumnLink
        networkResult={networkResult}
        billingDetailsForNetwork={billingDetailsForNetwork}
        setBillingDetailsForNetwork={setBillingDetailsForNetwork}
        networkLabel={networkLabel} />
      <DataUsibilityColumn networkResult={networkResult} />
    </>
  );

  const populationColumns = (
    <>
      <MedicarePercentColumn networkResult={networkResult} />
      <NpiCountColumn networkResult={networkResult} />
      <PopulationDataUsibilityColumn networkResult={networkResult} />
    </>
  );

  const networkRates = {
    [EVALTYPE.POPULATION]: {
      tableHeaders: PopultionTableHeaders,
      networkRows: () => populationColumns,
    },
    [EVALTYPE.PROVIDER]: { tableHeaders: StudyTableHeaders, networkRows: () => studyColumns },
    [EVALTYPE.MARKET]: { tableHeaders: StudyTableHeaders, networkRows: () => studyColumns },
  };

  const NetworkRateRows = networkRates[evalType].networkRows;
  const TableHeaderRows = networkRates[evalType].tableHeaders;

  return (
    <Table sx={{ tableLayout: 'fixed' }}>
      <TableHead>
        <TableRow border="true">
          <TableHeaderRows />
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow border="true">
          <NetworkRateRows
            networkResult={networkResult}
            categoryDetails={categoryDetails}
            networkCode={networkCode}
            billingClass={billingClass}
            networkLabel={networkLabel}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
}
